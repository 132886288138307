
@font-face {
    font-family:"larken";
    src:url("larken-bold.woff2") format("woff2"),url("larken-bold.woff") format("woff"),url("larken-bold.otf") format("opentype");
    font-style:normal;font-weight:700;
}

@font-face {
    font-family:"larken";
    src:url("larken-light.woff2") format("woff2"),url("larken-light.woff") format("woff"),url("larken-light.otf") format("opentype");
    font-style:normal;font-weight:300;
}
