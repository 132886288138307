@font-face {
    font-family:"kiona";
    src:url("Kiona-Bold.woff2") format("woff2"),url("Kiona-Bold.woff") format("woff"),url("Kiona-Bold.ttf") format("truetype");
    font-style:normal;font-weight:700;
}

@font-face {
    font-family:"kiona";
    src:url("Kiona-BoldItallic.woff2") format("woff2"),url("Kiona-BoldItallic.woff") format("woff"),url("Kiona-BoldItallic.ttf") format("truetype");
    font-style:italic;font-weight:700;
}

@font-face {
    font-family:"kiona";
    src:url("Kiona-Itallic.woff2") format("woff2"),url("Kiona-Itallic.woff") format("woff"),url("Kiona-Itallic.ttf") format("truetype");
    font-style:italic;font-weight:400;
}

@font-face {
    font-family:"kiona";
    src:url("Kiona-Light.woff2") format("woff2"),url("Kiona-Light.woff") format("woff"),url("Kiona-Light.ttf") format("truetype");
    font-style:normal;font-weight:300;
}

@font-face {
    font-family:"kiona";
    src:url("Kiona-LightItallic.woff2") format("woff2"),url("Kiona-LightItallic.woff") format("woff"),url("Kiona-LightItallic.ttf") format("truetype");
    font-style:italic;font-weight:300;
}

@font-face {
    font-family:"kiona";
    src:url("Kiona-Regular.woff2") format("woff2"),url("Kiona-Regular.woff") format("woff"),url("Kiona-Regular.ttf") format("truetype");
    font-style:normal;font-weight:400;
}

@font-face {
    font-family:"kiona";
    src:url("Kiona-SemiBold.woff2") format("woff2"),url("Kiona-SemiBold.woff") format("woff"),url("Kiona-SemiBold.ttf") format("truetype");
    font-style:normal;font-weight:600;
}

@font-face {
    font-family:"kiona";
    src:url("Kiona-SemiBoldItallic.woff2") format("woff2"),url("Kiona-SemiBoldItallic.woff") format("woff"),url("Kiona-SemiBoldItallic.ttf") format("truetype");
    font-style:italic;font-weight:600;
}
