@tailwind base;
@tailwind components;
@tailwind utilities;

@import './fonts/kiona/kiona.css';

@import './fonts/larken/larken.css';

/* set body bg to footer bg for seamless scrolling at bottom of page */ 
/* https://www.30secondsofcode.org/css/s/footer-at-the-bottom/ */

@layer base {
  body {
    @apply bg-sage-200 font-body text-paris-white-900 flex flex-col min-h-screen
  }
}